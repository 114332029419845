import * as React from "react"
import { StaticQuery, graphql } from "gatsby"
import Seo from "../components/seo"
import Header from "./header"
import { ConfirmProvider } from "material-ui-confirm"

import {
  ThemeProvider,
  createTheme,
  useTheme,
  responsiveFontSizes,
} from "@mui/material/styles"
import getDesignTokens from "../components/util/Theme"

import IconButton from "@mui/material/IconButton"
import Brightness4Icon from "@mui/icons-material/Brightness4"
import Brightness7Icon from "@mui/icons-material/Brightness7"
import Box from "@mui/material/Box"
import { Grid } from "@mui/material"
import { Typography } from "@mui/material"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import { LocalizationProvider } from "@mui/lab"
import Link from "@mui/material/Link"
import Divider from "@mui/material/Divider"

const ColorModeContext = React.createContext({ toggleColorMode: () => {} })

function DarkModeApp() {
  const theme = useTheme()
  const colorMode = React.useContext(ColorModeContext)
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ color: "text.primary" }}
      >
        <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
          {theme.palette.mode} mode
        </Box>

        <IconButton
          sx={{ ml: 1 }}
          onClick={colorMode.toggleColorMode}
          color="inherit"
          aria-label="enable dark mode"
        >
          {theme.palette.mode === "dark" ? (
            <Brightness7Icon />
          ) : (
            <Brightness4Icon />
          )}
        </IconButton>
      </Grid>
    </>
  )
}

const Layout = ({ children }) => {
  const [mode, setMode] = React.useState("light")
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        localStorage.setItem("mode", mode === "light" ? "dark" : "light")
        setMode(prevMode => (prevMode === "light" ? "dark" : "light"))
      },
    }),
    [mode]
  )

  React.useEffect(() => {
    if (localStorage.getItem("mode")) {
      setMode(localStorage.getItem("mode"))
    }
  }, [])

  const theme = React.useMemo(() => {
    let theme = createTheme(getDesignTokens(mode))
    theme = responsiveFontSizes(theme)
    return theme
  }, [mode])

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ConfirmProvider>
            <Typography
              component={"div"}
              sx={{
                bgcolor: theme.palette.background.default,
                color: theme.palette.text.primary,
                minHeight: "100%",
              }}
            >
              <div className="global-wrapper">
                <StaticQuery
                  query={graphql`
                    query SiteTitleQuery {
                      site {
                        siteMetadata {
                          title
                          description
                          menuLinks {
                            name
                            link
                          }
                        }
                      }
                    }
                  `}
                  render={data => (
                    <React.Fragment>
                      <Seo title={data.site.siteMetadata.title}></Seo>
                      <Header
                        menuLinks={data.site.siteMetadata.menuLinks}
                        siteTitle={data.site.siteMetadata.title}
                        DarkModeApp={DarkModeApp}
                      />
                      <main>{children}</main>
                    </React.Fragment>
                  )}
                />
                <footer>
                  <Typography component={"span"} variant="body2">
                    <Grid
                      container
                      direction={{ xs: "column", md: "row" }}
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={3} align="left">
                        © {new Date().getFullYear()}, All rights reserved.
                      </Grid>
                      <Grid item xs="auto" align="right">
                        <Box
                          sx={{
                            p: "0px 4px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ p: "0px 4px" }}>
                            <Link href="/privacy-policy" underline="hover">
                              Privacy policy
                            </Link>
                          </Box>
                          <Divider
                            orientation="vertical"
                            sx={{ height: 20, m: 0.5 }}
                          />
                          <Box sx={{ p: "0px 4px" }}>
                            <Link href="/terms-of-use" underline="hover">
                              Terms of use
                            </Link>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Typography>
                </footer>
              </div>
            </Typography>
          </ConfirmProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default Layout
