import { amber } from "@mui/material/colors"

// Selected color palette:
// https://material.io/resources/color/#!/?view.left=1&view.right=0&secondary.color=651FFF&primary.color=40C4FF&primary.text.color=000000&secondary.text.color=ffffff
const getDesignTokens = mode => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: { main: "#651fff" },
          secondary: { main: "#651fff" },
          background: {
            default: "#fafafa",
            paper: "#f5f5f5",
          },
          text: {
            primary: "#000000",
            secondary: "#000000",
          },
        }
      : {
          // palette values for dark mode
          primary: amber,
          secondary: amber,
          divider: "rgba(255, 255, 255, 0.12)",
          background: {
            default: "#121212",
            paper: "#121212",
          },
          text: {
            primary: "#fff",
            secondary: "rgba(255, 255, 255, 0.7)",
            disabled: "rgba(255, 255, 255, 0.5)",
          },
        }),
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
})

export default getDesignTokens
