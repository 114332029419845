import React from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import MenuIcon from "@mui/icons-material/Menu"
import Container from "@mui/material/Container"
import Button from "@mui/material/Button"
import Drawer from "@mui/material/Drawer"

import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import HomeIcon from "@mui/icons-material/Home"
import ArticleIcon from "@mui/icons-material/Article"
import CommentIcon from "@mui/icons-material/Comment"
import InfoIcon from "@mui/icons-material/Info"
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline"

const Header = ({ siteTitle, menuLinks, DarkModeApp }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(false)
  const drawerWidth = 280

  const toggleDrawer = isOpen => event => {
    if (isOpen === undefined) isOpen = !anchorElNav
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setAnchorElNav(isOpen)
  }

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "background.paper",
          color: "text.primary",
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/")
              }}
            >
              {siteTitle}
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={toggleDrawer()}
                color="inherit"
                aria-controls={anchorElNav ? "menu-appbar" : undefined}
                aria-expanded={anchorElNav ? "true" : undefined}
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/")
              }}
            >
              {siteTitle}
            </Typography>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
              }}
            >
              {menuLinks.map(link => (
                <Button
                  key={link.link}
                  onClick={() => {
                    navigate(link.link)
                  }}
                  sx={{ mr: 2, color: "text.primary", display: "block" }}
                >
                  {link.name}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <DarkModeApp />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        open={anchorElNav}
        onClose={toggleDrawer(false)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Box
          sx={{ overflow: "auto", mt: 8 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {menuLinks.map(link => (
              <ListItem
                button
                key={link.link}
                onClick={() => {
                  toggleDrawer(false)
                  navigate(link.link)
                }}
              >
                <ListItemIcon>
                  {link.name === "Home" && <HomeIcon />}
                  {link.name === "Articles" && <ArticleIcon />}
                  {link.name === "Contact" && <CommentIcon />}
                  {link.name === "About" && <InfoIcon />}
                  {link.name === "Install app" && <DownloadForOfflineIcon />}
                </ListItemIcon>
                <ListItemText primary={link.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}
Header.defaultProps = {
  siteTitle: ``,
}
export default Header
